import React from 'react'
import './Cart.css'
function Cart() {
  return (
    <div className='cartPage'>
      {/* cart left part*/}
      <div className='cartLeftContainer'>

      </div>
      {/* cart righ part*/}
      <div className='cartRightContainer'>

      </div>
    </div>
  )

}

export default Cart