import { createSlice } from "@reduxjs/toolkit";


export const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cart: []
    },
    reducers: {
        addToCart: (state, action) => {
            console.log(`addToCart state is ${JSON.stringify(state)}`)
            const itemPresent = state.cart.find((item) => item.id === action.payload.id);
            if (itemPresent) {
                itemPresent.quality++;
            } else {
                state.cart.push({ ...action.payload, quantity: 1 });
            }
            console.log(`addToCart end state is ${JSON.stringify(state)}`)
        },
        removeFromCart: (state, action) => {
            console.log(`removeFromCart state is ${JSON.stringify(state)}`)
            const afterRemovingItem = state.cart.filter((item) => item.id !== action.payload.id);
            state.cart = afterRemovingItem;
            console.log(`removeFromCart end state is ${JSON.stringify(state)}`)
        },
        incrementQuantity: (state, action) => {
            const itemPresent = state.cart.find((item) => item.id === action.payload.id);
            itemPresent.quality++;
        },
        decrementQuantity: (state, action) => {
            const itemPresent = state.cart.find((item) => item.id === action.payload.id);
            if (itemPresent.quality === 1) {
                const afterRemovingItem = state.cart.filter((item) => item.id !== action.payload.id);
                state.cart = afterRemovingItem;
            } else {
                itemPresent.quality--;
            }
        }
    }
}
);


export const {addToCart,removeFromCart,incrementQuantity,decrementQuantity} = cartSlice.actions;

export default cartSlice.reducer;