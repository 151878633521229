import React from 'react'
import "./Header.css"
import CompanyLogo from "../../assets/CompanyLogoWhite.png"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Header() {
    const cart = useSelector((state) => state.cart.cart);
    const navigate = useNavigate();

    const navigateToCart =()=>{
        navigate("/cart");
    }

    return (
        <>
            {/*First Band of the Header */}
            <div className='header'>
                {/*Image Logo Here */}
                <img
                    style={{ width: 100, height: 100, marginTop: 5 }}
                    className='image'
                    src={CompanyLogo}
                    alt='Laptop Parts India'
                />
                {/* Search Bar Goes Here */}
                <div className='headerInputContainer'>
                    <input className='headerInput' type='text' placeholder='Search laptop parts here' />
                    <SearchOutlinedIcon style={
                        {
                            color: "white",
                            marginLeft: 4,
                            marginTop: 2
                        }
                    } />
                </div>

                <div style={{ position: 'relative', cursor: 'pointer' }}>
                        <a href="//api.whatsapp.com/send?phone=919884388818&text=Hello I want to buy your product, lets connect" target="_blank" rel="noreferrer">
                        <h4 className='headerText'>
                            <WhatsAppIcon style={
                                {
                                    color: "white",
                                    marginLeft: 2,
                                    marginTop: 2,
                                    gap: 1,
                                    paddingRight: 2
                                }}
                            />
                            WhatsApp Us
                            </h4>
                        </a>
                    
                </div>
                <div onClick={navigateToCart} style={{ position: 'relative', cursor: 'pointer' }}>
                    <ShoppingCartIcon style={{ color: 'white' }} />
                    <span
                        style={{
                            position: "absolute",
                            left: 14,
                            right: 14,
                            backgroundColor: 'white',
                            width: 14,
                            height: 14,
                            borderRadius: 7,
                            fontSize: 12,
                            fontWeight: 400,
                            textAlign: "center"
                        }}
                    >
                        {cart.length}


                    </span>
                </div>
            </div>

            {/*Second Band of the Header */}
            <div className='headerSecondBand'>
                <MenuIcon style={{ color: "white", paddingTop: "6" }} />
                <p className='headerSecondBandText'>All Categories</p>
                <p className='headerSecondBandText'>Panels</p>
                <p className='headerSecondBandText'>Cabels</p>
                <p className='headerSecondBandText'>Parts</p>
                <p className='headerSecondBandText'>Brands</p>
                <p className='headerSecondBandText'>Accessories</p>
            </div>
        </>
    );
}

export default Header