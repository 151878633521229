import React from 'react';
import './Home.css';
import Header from '../components/header/Header';
import Body from '../components/landingpage/Body';
import PrimaryFooter from '../components/footers/PrimaryFooter';

function Home() {
    return (
        <div className='home'>
                <Header />
                <Body />
                <PrimaryFooter />
        </div>
    )
}

export default Home
