import React, { useEffect, useState } from 'react'

import "./Body.css"
import Product from '../ProductItems/Product';

function Body() {
  const [products, setProducts] = useState([]);
  
  useEffect(() => {  
    const fetchProducts = async () => {
      await fetch('https://fakestoreapi.com/products')
        .then(res => res.json())
        .then(data => setProducts(data))
    };

    fetchProducts();
  }, [])

  return (
    <div className='homepageBody'>
      <div className='homePageItems'>
        {/* <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            <ExampleCarouselImage text="First slide" />
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <ExampleCarouselImage text="Second slide" />
            <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <ExampleCarouselImage text="Third slide" />
            <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel> */}
        <>

        </>
        {products.map((item, index) => (

          <Product spec={item} key={item.id} />
        ))}

      </div>

    </div>
  )
}

export default Body