import React from 'react'
import "./PrimaryFooter.css"


function PrimaryFooter() {
    return (
        <>
            {/*Second Band of the Header */}
            <div className='headerSecondBand'>
                <p className='headerSecondBandText'>All Categories</p>
                <p className='headerSecondBandText'>Panels</p>
                <p className='headerSecondBandText'>Cabels</p>
                <p className='headerSecondBandText'>Parts</p>
                <p className='headerSecondBandText'>Brands</p>
                <p className='headerSecondBandText'>Accessories</p>
            </div>
        </>
    );
}

export default PrimaryFooter