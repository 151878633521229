import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart, removeFromCart } from '../../redux/CardSlice';

import './Product.css'
function Product({ spec }) {
    const cart = useSelector((state) => state.cart.cart);
    //console.log(`Product :: ${cart}`);
    const dispatch = useDispatch();

    const addItemToCart = (spec) => {
        dispatch(addToCart(spec));
    };

    const removeItemFromCart = (spec) => {
        dispatch(removeFromCart(spec));
    };

    const enquireNow = (spec) => {
        console.log(`enquireNow clicked with product details as ${JSON.stringify(spec)}`);
        const newPageUrl = `https://api.whatsapp.com/send?phone=919884388818&text=Hello I would like to buy ${spec.title}`;
        // route to new page by changing window.location
        window.open(newPageUrl, "_blank"); //to open new page
    }
    return (
        <div className='productItem'>
            {/* Product Image */}
            <img style={{ width: 200, height: 200, marginLeft: "auto", marginRight: "auto" }} src={spec.image} alt={spec.description}/>
            {/* Product Pallet Item title */}
            <p>{spec.title.length > 30 ? spec.title.substr(0, 30) : spec.title}</p>
            {/* Product Pallet Item Description */}
            <p>{spec.description.length > 60 ? spec.description.substr(0, 60) : spec.description}</p>
            {/* Product Price*/}
            <p>Rs.{spec.price}</p>

            {/* Add to Cart Button Logic */}
            {cart && cart.some((x) => x.id === spec.id) ?
                (
                    <button onClick={() => removeItemFromCart(spec)} className='productItemBuy'>
                        Remove From Cart
                    </button>
                ) : (
                    <button onClick={() => addItemToCart(spec)} className='productItemBuy'>
                        Add To Cart
                    </button>
                )
            }

            {/** Enquire Button*/}
            <button className='productItemEnquire' onClick={() => enquireNow(spec)}>
                Enquire
            </button>

        </div >
    )
}

export default Product
